<template>
  <div id="app">
    <Unity src="/static/Build/ProductViewer2018_2.json" width="100vw" height="100vh" unityLoader="/static/Build/UnityLoader.js"></Unity>
  </div>
  
</template>

<script>
import Unity from 'vue-unity-webgl'

export default {
  name: 'App',
  components: {
    Unity
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#unity-container{
  width: 100vw;
  height: 100vh;
}

.webgl-content .footer {
  display: none;
}
</style>
